import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
  useCallback,
  Fragment,
} from "react";
import Util from "../../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import FloatInput from "../../../components/FloatInput/FloatInput";
import { scrollToRefObject } from "../../../utils/helper";
import { updateCustomerInfo } from "../../../stores/customer/customer.action";
import { ABIC_PRODUCT_CODES, BSH_PRODUCT_CODES } from "utils/const";

const MotobikeInput = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { productCode, isRenewal } = props;
  const { customerInfo } = useSelector((state) => state.customer);
  const { userInfo } = useSelector((state) => state.appParams);

  const [chassisNo, setChassisNo] = useState(
    customerInfo && customerInfo.chassisNo ? customerInfo.chassisNo : ""
  );
  const [engineNo, setEngineNo] = useState(
    customerInfo && customerInfo.engineNo ? customerInfo.engineNo : ""
  );
  const [licensePlates, setLicensePlates] = useState(
    customerInfo && customerInfo.licensePlates && Util.validateLicensePlatesRemoveCharSpecial(customerInfo.licensePlates) ? customerInfo.licensePlates : ""
  );

  const [carOwner, setCarOwner] = useState(customerInfo && customerInfo.carOwner ? customerInfo.carOwner.toUpperCase() : "");

  const [isValidLicensePlates, setIsValidLicensePlates] = useState(true);
  const [isValidCharSpecial, setIsValidCharSpecial] = useState(true);
  const [isValidCarOwner, setIsValidCarOwner] = useState(true);
  const [isValidChassisNo, setIsValidChassisNo] = useState(false);
  const [isValidEngineNo, setIsValidEngineNo] = useState(false);

  const refCarOwner = useRef();
  const refLicensePlates = useRef();
  const refScrollLicensePlates = useRef();
  const refChassisNo = useRef();
  const refEngineNo = useRef();

  useEffect(() => {
    customerInfo.licensePlates = licensePlates;
    customerInfo.carOwner = carOwner;
    customerInfo.chassisNo = chassisNo;
    customerInfo.engineNo = engineNo;
    dispatch(updateCustomerInfo(customerInfo));
  }, [customerInfo, dispatch, licensePlates, carOwner, engineNo, chassisNo]);

  const onChangeEvent = (value) => {
    // const valueConvert = Util.convertLicensePlates(value);
    // let valid = Util.validateLicensePlatesRemoveCharSpecial(value);
    // setIsValidLicensePlates(valid);
    setLicensePlates(value.toUpperCase());
  };

  const onEndEditing = () => {
    if(Util.validateCharSpecial(licensePlates)) {
      setIsValidCharSpecial(false);
      setIsValidLicensePlates(true);
    }else if(!Util.validateLicensePlatesRemoveCharSpecial(licensePlates)) {
      setIsValidLicensePlates(false);
      setIsValidCharSpecial(true);
    }else {
      setIsValidLicensePlates(true);
      setIsValidCharSpecial(true);
    }
  }

  // const onKeyDownEvent = (e) => {
  //   if (e.key === "Backspace") {
  //     if (licensePlates.length === 5) {
  //       e.preventDefault();
  //       const value = licensePlates.substring(0, licensePlates.length - 2);
  //       let valid = Util.validateLicensePlates(value);
  //       setIsValidLicensePlates(valid);
  //       setLicensePlates(value);
  //     }
  //   }
  // };

  const onClearValueEvent = () => {
    setLicensePlates("");
  };

  const getVehicleInfo = () => {
    return {
      carOwner,
      licensePlates,
      engineNo,
      chassisNo
    };
  };

  // const onChassisNoChange = (value) => {
  //   const nameValue = Util.toTitleCase(value);
  //   const valid = Util.validateChassisNo(nameValue);
  //   setIsValidChassisNo(valid);
  //   setChassisNo(nameValue);
  // };

  // const onEngineNoChange = (value) => {
  //   const nameValue = Util.toTitleCase(value);
  //   const valid = Util.validateEngineNo(nameValue);
  //   setIsValidEngineNo(valid);
  //   setEngineNo(nameValue);
  // };

  const onChassisNoChange = (value) => {
    const nameValue = Util.toTitleCase(value);
    if(value) {
      const valid = Util.validateChassisNo(nameValue);
      setIsValidChassisNo(valid);
    }else {
      setIsValidChassisNo(false);
    }
    setChassisNo(nameValue);
  };

  const onEngineNoChange = (value) => {
    const nameValue = Util.toTitleCase(value);
    if(value) {
      const valid = Util.validateEngineNo(nameValue);
      setIsValidEngineNo(valid);
    }else {
      setIsValidEngineNo(false);
    }
    setEngineNo(nameValue);
  };

  const onNameChange = (value) => {
    const valid = Util.validateName(value);
    setIsValidCarOwner(valid);
    setCarOwner(value);
  };

  const setValidateInfo = (validCarOwner, isValidLicensePlates, validChassisNo, validEngineNo) => {
    return isValidLicensePlates && validCarOwner  && !validChassisNo && !validEngineNo && isValidCharSpecial;  
  }

  useImperativeHandle(ref, () => ({
    validateInfo() {
      const isValidLicensePlates = Util.validateLicensePlatesRemoveCharSpecial(licensePlates);
      setIsValidLicensePlates(isValidLicensePlates);
      const isValidCharSpecial = Util.validateCharSpecial(licensePlates);
      setIsValidCharSpecial(!isValidCharSpecial);
      let validCarOwner = Util.validateName(carOwner);
      setIsValidCarOwner(validCarOwner);
      // const isValidChassisNo = Util.validateChassisNo(chassisNo);
      // setIsValidChassisNo(isValidChassisNo);
      // const isValidEngineNo = Util.validateEngineNo(engineNo);
      // setIsValidEngineNo(isValidEngineNo);

      if (!isValidLicensePlates || isValidCharSpecial) {
        scrollToRefObject(refScrollLicensePlates);
      }
      if (!validCarOwner) {
        scrollToRefObject(refCarOwner);
      }
      let validChassisNo = false;
      let validEngineNo = false;
      if(chassisNo || engineNo) {
        validChassisNo = Util.validateChassisNo(chassisNo);
        validEngineNo = Util.validateEngineNo(engineNo);
        setIsValidChassisNo(validChassisNo);
        setIsValidEngineNo(validEngineNo);
      }
      if(!chassisNo && !engineNo) {
        setIsValidChassisNo(validChassisNo);
        setIsValidEngineNo(validEngineNo);
      }
      return setValidateInfo(validCarOwner, isValidLicensePlates, validChassisNo, validEngineNo);
    },
    validateInfoByPhone() {
      const isValidLicensePlates = Util.validateLicensePlatesRemoveCharSpecial(licensePlates);
      setIsValidLicensePlates(isValidLicensePlates);
      const isValidCharSpecial = Util.validateCharSpecial(licensePlates);
      setIsValidCharSpecial(!isValidCharSpecial);
      let validCarOwner = Util.validateName(carOwner);
      setIsValidCarOwner(validCarOwner);
      
      return setValidateInfo(validCarOwner, isValidLicensePlates);
    },
    getVehicleInfo() {
      return getVehicleInfo();
    },
  }));

  const setLabelError = useCallback(() => {
    let labelError = "";
    if(isValidLicensePlates && isValidCharSpecial) {
      labelError = "Biển số xe. VD: 29C12345 or 29CD12345";
    }else {
      if(!isValidCharSpecial) {
        labelError = "Biển số xe chỉ được nhập chữ và số vd 29C12345";
      }else if(!isValidLicensePlates) {
        labelError = Util.invalidInfo("licensePlates");
      }
    }
    return labelError;
  },[isValidLicensePlates, isValidCharSpecial]);

  return (
    <>
      <div className="form_title">
        <div>Thông tin xe</div>
      </div>
      <div className="form_row" ref={refScrollLicensePlates}>
        <FloatInput
          ref={refCarOwner}
          id="refCarOwner"
          label={
            isValidCarOwner ? "Họ và tên chủ xe" : Util.invalidInfo("fullName")
          }
          value={carOwner}
          iconClear={true}
          textTransform={"uppercase"}
          error={!isValidCarOwner}
          onChangeText={onNameChange}
          require
        />
      </div>
      <div className="form_row" ref={refScrollLicensePlates}>
        <FloatInput
          ref={refLicensePlates}
          label={setLabelError()}
          value={licensePlates}
          iconClear={true}
          error={!isValidLicensePlates || !isValidCharSpecial}
          onChangeEvent={onChangeEvent}
          // onKeyDownEvent={onKeyDownEvent}
          onEndEditing={onEndEditing}
          onClearValueEvent={onClearValueEvent}
          require 
        />
      </div>
      {
        !isRenewal && <Fragment>
          <div className="form_row" ref={refScrollLicensePlates}>
            <FloatInput
              ref={refChassisNo}
              id="refChassisNo"
              label={
                !isValidChassisNo ? "Số khung" : Util.invalidInfo("chassisNo")
              }
              value={chassisNo}
              iconClear={true}
              onChangeText={onChassisNoChange}
              error={isValidChassisNo}
            />
          </div>
          <div className="form_row" ref={refScrollLicensePlates}>
            <FloatInput
              ref={refEngineNo}
              id="refEngineNo"
              label={
                !isValidEngineNo ? "Số máy" : Util.invalidInfo("engineNo")
              }
              error={isValidEngineNo}
              value={engineNo}
              iconClear={true}
              onChangeText={onEngineNoChange}
            />
          </div>
        </Fragment>
      }
    </>
  );
});

export default React.memo(MotobikeInput);
