import React, { useRef, useState } from 'react';
import "./RenewalScreen.scss"
import { SafeAreaView } from 'components/SafeAreaView';
import InsuranceInfo from './components/InsuranceInfo';
import CarInput from 'screens/CustomerInfoScreen/components/CarInput';
import colors from 'utils/colors';
import Utils from 'utils/utils';
import Button from 'components/Button';
import { useEffect } from 'react';
import ContactInput from 'screens/CustomerInfoScreen/components/ContactInput';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCities } from 'stores/address/address.action';
import { clearCheckPayment, clearErrMess, createOrder, createPolicyDraft } from 'stores/payment/payment.action';
import ZaloPaySDK from 'utils/zalopay';
import { PATH, TYPE_INSURANCE } from 'utils/const';
import { useQuery } from 'screens/HomeScreen/HomeScreen';
import MotobikeInput from 'screens/CustomerInfoScreen/components/MotobikeInput';
import { AppApiInstance } from 'api/app-api';

const dataConsentPayment = [{
  "motobike": [
    "Họ và tên của chủ xe",
    "Biển số xe",
    "Họ tên của Bên mua bảo hiểm",
    "Số điện thoại",
    "Email",
    "Địa chỉ",
  ]
}, {
  "car": [
    "Họ và tên của chủ xe",
    "Biển số xe",
    "Hãng xe",
    "Hiệu xe",
    "Số khung",
    "Số máy",
    "Họ tên của Bên mua bảo hiểm",
    "Số điện thoại",
    "Email",
    "Địa chỉ",
  ]
}];

const RenewalScreen = () => {
  const productType = useQuery().get('product_type');
  const year = useQuery().get('year');

  const vehicleRef = useRef();
  const insuranceInfoRef = useRef();
  const contactRef = useRef();

  const dispatch = useDispatch();
  const history = useHistory();
  const [fee, setFee] = useState(0);
  const [productCode, setProductCode] = useState("");

  const {
    responseCreateOrder,
    insurance,
    createOrderData,
    creatingOrder,
    createOrderErrMsg,
    hasPayment,
  } = useSelector((state) => state.payment);

  const getFee = (value) => setFee(value);
  const getProductCode = (value) => setProductCode(value);

  const onNextStepPress = async () => {
    const insuranceDetail = getInsuranceDetail(insuranceInfoRef);
    // get form insuranceInfo
    const insuranceInfo = getInsuranceInfo(insuranceInfoRef);
    const isValidInsuranceInfo = validInsuranceInfo(insuranceInfoRef);
    // get form vehicleInfo
    const vehicleInfo = getVehicleInfo(vehicleRef);
    const isValidVehicleInfo = validVehicleInfo(vehicleRef);
    // get form contract
    const contract = getCustomerInfo(contactRef);
    const isValidContract = contactValid(contactRef);
    let district = contract.district;
    let ward = contract.ward;
    if(Object.keys(contract.district).length === 0 && Object.keys(contract.ward).length === 0) {
      const { data: { getDistricts } } = await AppApiInstance.getDistricts({  
        city: contract.city.code
      });
      const { data: { getWards } } = await AppApiInstance.getWards({  
        city: contract.city.code,
        district: getDistricts[0].code
      });
      district = getDistricts[0];
      ward = getWards[0];
    }
    if (isValidVehicleInfo && isValidContract && isValidInsuranceInfo) {
      const key = productType === TYPE_INSURANCE.CAR ? "_convertLicensePlatesCar" : "_convertLicensePlates";
      vehicleInfo.licensePlates = Utils[key](vehicleInfo.licensePlates);
      const _insurance = {...insuranceDetail, ...insuranceInfo, status: "waiting_for_approve", isSendZNSNotify: true};
      const _contract = {...contract, district, ward, address: contract.address || "xxx" };
      const _recipient = {
        addressRecipient: "",
        cityRecipient: {},
        districtRecipient: {},
        fullNameRecipient: "",
        phoneRecipient: "",
        wardRecipient: {}
      };
      dispatch(
        createOrder(productType, _insurance, _contract, vehicleInfo, _recipient)
      );
    }
  };

  const handleCreatePolicyDraft = (interval) => {
    const insuranceDetail = getInsuranceDetail(insuranceInfoRef);
    const isValidVehicleInfo = validVehicleInfoByPhone(vehicleRef);
    const isValidContract = contactValidByPhone(contactRef);
    const contract = getCustomerInfo(contactRef);
    const vehicleInfo = getVehicleInfo(vehicleRef);

    if (isValidContract && isValidVehicleInfo && Object.keys(insuranceDetail).length) {
      const key = productType === TYPE_INSURANCE.CAR ? "_convertLicensePlatesCar" : "_convertLicensePlates";
      vehicleInfo.licensePlates = Utils[key](vehicleInfo.licensePlates);
      dispatch(
        createPolicyDraft(productType, insuranceDetail, contract, vehicleInfo)
      );
      // console.log(insuranceDetail, contract, vehicleInfo);
      clearInterval(interval);
    }
  }

  const onBlurPhone = () => {
    const interval = setInterval(() => {
      handleCreatePolicyDraft(interval)
    }, 1000);
  }

  useEffect(() => {
    if (creatingOrder) {
      ZaloPaySDK.showLoading();
    } else {
      ZaloPaySDK.hideLoading();
    }
  }, [creatingOrder]);

  useEffect(() => {
    if (createOrderErrMsg) {
      const errMsg = createOrderErrMsg;
      ZaloPaySDK.showDialog({
        message: errMsg,
        buttons: [{ text: "Đóng" }],
      });
      return () => {
        dispatch(clearErrMess());
      }
    }
  }, [createOrderErrMsg]);

  useEffect(() => {
    if (responseCreateOrder && createOrderData && insurance && hasPayment) {
      dispatch(clearCheckPayment());
      history.push({
        pathname: `${PATH}/contract-review/${responseCreateOrder.quotation_code}`,
        search: "flat=isRenewal&subview=cashier"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseCreateOrder, createOrderData, hasPayment]);

  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  return (
    <div>
      <SafeAreaView id="wrapper-scroll" style={styles}>
        <div style={{ paddingBottom: 10 }}>
          <div className="margin-bottom-16">
            <InsuranceInfo productType={productType} year={year} ref={insuranceInfoRef} getProductCode={getProductCode} getFee={getFee} />
          </div>
          <div className='form'>
            {productType === TYPE_INSURANCE.MOTOBIKE ? (
              <MotobikeInput ref={vehicleRef} productCode={productCode} isRenewal={true} />
            ) : productType === TYPE_INSURANCE.CAR ? <CarInput ref={vehicleRef} productCode={productCode} /> : null}
            <ContactInput
              ref={contactRef}
              minDateEffect={1}
              productCode={productCode}
              minAge={0}
              maxAge={0}
              insuranceDetail={{}}
              isRenewal={[TYPE_INSURANCE.MOTOBIKE, TYPE_INSURANCE.CAR].includes(productType)}
              onBlurPhone={onBlurPhone}
            />
            <div className="form_row">
              <div className="w-100 font-size-12">
                <strong>Bằng việc tiếp tục thanh toán, bạn đồng ý với Chính sách Quyền riêng tư và cho phép Zalopay & đối tác Savemoney chia sẻ thông tin cho đối tác bảo hiểm để thực hiện hợp đồng bảo hiểm:</strong>
                <ul style={{marginLeft: -10, marginTop: 5}}>
                  {
                    dataConsentPayment.find(f => f[productType])[productType].map((m, i) => (
                      <li style={{margin: "3px 0px"}} key={i}>{m}</li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </SafeAreaView>
      <div className="button-fixed-bottom">
        <div className="price">{Utils.formatMoneyVND(fee)}đ</div>
        <Button
          className="btn-main"
          onPress={onNextStepPress}
          style={{ backgroundColor: colors.main }}
        >
          Tiếp tục
        </Button>
      </div>
    </div>
  )
}

const styles = {
  height: "calc(100vh - env(safe-area-inset-bottom) - 76px)",
  bottom: 76,
  position: "fixed",
  top: 0,
  overflowY: 'auto'
};

export default RenewalScreen;

const validInsuranceInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.validInsuranceInfo();
  }
}

const getInsuranceDetail = (ref) => {
  if (ref && ref.current) {
    return ref.current.getInsuranceDetail();
  }
}

const getInsuranceInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.getInsuranceInfo();
  }
}

const validVehicleInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.validateInfo();
  }
};

const getVehicleInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.getVehicleInfo();
  }
};

const getCustomerInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.getCustomerInfo();
  }
};

const contactValid = (ref) => {
  if (ref && ref.current) {
    return ref.current.validateInfo();
  }
};

const contactValidByPhone = (ref) => {
  if (ref && ref.current) {
    return ref.current.validateInfoByPhone();
  }
};

const validVehicleInfoByPhone = (ref) => {
  if (ref && ref.current) {
    return ref.current.validateInfoByPhone();
  }
};