import { types } from "./contract.type";

export const getContracts = (userId, contractType, langId) => {
  return {
    type: types.GETTING_CONTRACT_LIST,
    payload: { userId, contractType, langId },
  };
};

export const getContractDetail = (quotationCode, status, queryMUID, mode) => {
  return {
    type: types.GETTING_CONTRACT_DETAIL,
    payload: { quotationCode, status, queryMUID, mode },
  };
};

export const getInsuranceEmbed = (phoneNumber) => {
  return {
    type: types.GETTING_INSURANCE_EMBED_LIST,
    payload: { phoneNumber },
  };
};