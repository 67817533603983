import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ZaloPaySDK from "../../utils/zalopay";
import "./RenewalInsuranceDetailsScreen.scss";
import InsuranceInfo from "./components/InsuranceInfo";
import ContractOwnerInfo from "../ContractReviewScreen/components/ContractOwnerInfo";
import RecipientInfo from "./components/RecipientInfo";
import MotobikeInfo from "../ContractReviewScreen/components/MotobikeInfo";
import { SafeAreaView } from "../../components/SafeAreaView";
import ModalCoverageBenefit from "./components/ModalCoverageBenefit";
import images from "../../utils/images";
import { PATH, PRODUCT_TYPE, TYPE_INSURANCE } from "../../utils/const";
import trackingId from "../../utils/tracking";
import Utils from "../../utils/utils";
import { getContractDetail } from "../../stores/contract/contract.action";
import { getStringDuration } from "utils/helper";
import { updateCustomerInfo } from "stores/customer/customer.action";
import moment from "moment";
import { AppApiInstance } from "api/app-api";
import { clearPaymentInfo, createOrderCallback, payOrder } from "stores/payment/payment.action";

const RenewalInsuranceDetailsScreen = ({ type }) => {
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { quotationCode } = useParams();
  const dataFromPaymentPage = useLocation().state;
  const history = useHistory();
  const { userInfo } = useSelector((state) => state.appParams);
  const {
    contractDetail,
    gettingContractDetail,
    getContractDetailErrMsg,
  } = useSelector((state) => state.contract);
  const { customerInfo } = useSelector((state) => state.customer);
  const { createPayOrder, isPayment, paymentInfo, createPayOrderErrMsg } = useSelector((state) => state.payment);

  const { param } = useSelector((state) => state.insurance);

  useEffect(() => {
    const unListen = history.listen((newLocation, action) => {
      if (action === "POP") {
        if (param) {
          history.push(`${PATH}/home?product_type=${param}`);
        } else {
          history.goBack();
        }
      }
    });
    return () => {
      setTimeout(() => {
        unListen();
      }, 500);
    };
  }, [history]);

  useEffect(() => {
    dispatch(
      getContractDetail(
        quotationCode,
        (dataFromPaymentPage && dataFromPaymentPage.fromPaymentPage) ? "waiting_for_approve" : "",
        "",
        "detail"
      )
    );
  }, [dispatch, dataFromPaymentPage, quotationCode, userInfo.mUid]);

  useEffect(() => {
    if (gettingContractDetail) {
      ZaloPaySDK.showLoading();
    } else {
      ZaloPaySDK.hideLoading();
    }
  }, [gettingContractDetail]);

  useEffect(() => {
    if (getContractDetailErrMsg) {
      ZaloPaySDK.showDialog({
        message: getContractDetailErrMsg,
        buttons: [{ text: "Đóng" }],
      });
    }
  }, [getContractDetailErrMsg]);

  useEffect(() => {
    if (paymentInfo && isPayment) {
      const { carOwner, phone, fullName, email } = customerInfo;
      const data = { carOwner, phone, fullName, email };
      dispatch(updateCustomerInfo(data));
      ZaloPaySDK.payOrder(paymentInfo, cb);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentInfo, isPayment]);

  useEffect(() => {
    if (createPayOrder) {
      ZaloPaySDK.showLoading();
    } else {
      ZaloPaySDK.hideLoading();
    }
  }, [createPayOrder]);

  const cb = (data) => {
    console.log("function callback", data);
    dispatch(clearPaymentInfo());
  };

  useEffect(() => {
    ZaloPaySDK.setTitleV2("Chi tiết hợp đồng");
    ZaloPaySDK.tracking(trackingId.SUCCESSFUL_CONTRACT.LOAD_PAGE_SUCCESSFULLY);
  }, []);

  const viewCoverageBenefit = () => {
    ZaloPaySDK.tracking(trackingId.SUCCESSFUL_CONTRACT.SEE_POLICY_DETAIL);
    setIsOpenModal(true);
  };

  const viewDetailBinding = () => {
    if (ZaloPaySDK.isZaloPay()) {
      ZaloPaySDK.launchDeeplink(process.env.REACT_APP_ZPA_AGREEMENT_PAY_LIST);
    } else {
      ZaloPaySDK.navigateTo(process.env.REACT_APP_ZPI_AGREEMENT_PAY_LIST);
    }
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const goToQAScreen = () => {
    Utils.trackingBeforeRedirect(
      () => ZaloPaySDK.tracking(trackingId.SUCCESSFUL_CONTRACT.FAQ),
      () => history.push(`${PATH}/qa`, { productCode: contractDetail.productCode })
    );
  };

  const setDateEffect = (status, effective_date, expired_date) => {
    switch (status) {
      case "approved":
        if (moment(expired_date, "YYYY-MM-DD HH:mm:ss") < moment()) {
          return moment(expired_date).add(1, "days").format("DD/MM/YYYY HH:mm");
        }
      default:
        return moment().add(1, "days").format("DD/MM/YYYY HH:mm");
    }
  }

  const formatInsuranceDetail = async (contractDetail) => {
    const { productType, productId } = contractDetail;
    let insuranceDetail = {};
    switch (productType) {
      case PRODUCT_TYPE.motorbike_liability:
        insuranceDetail = contractDetail
        break;
      case PRODUCT_TYPE.car:
        const { data: { getMotobikeInsuranceDetail } } = await AppApiInstance.getMotobikeInsuranceDetail({
          productId,
          langId: "vi",
        });
        const benefits = getMotobikeInsuranceDetail.benefits.filter(f => contractDetail.benefitDetail.filter(f2 => f2.code === f.benefitCode).length !== 0);
        const { discountRate, productCode, carInfoDetail: { car_info, goods_cap, seat, use_type } } = contractDetail;
        insuranceDetail = {
          benefits: benefits.map(item => {
            return {
              premium: 0,
              sumInsured: item.max_sum_assured,
              benefitCode: item.benefitCode
            }
          }), seat,
          carInfo: car_info,
          goodsCap: goods_cap,
          usePurpose: use_type,
          premium: 0,
          productId, productCode,
          discountRate: +discountRate,
          tenantId: getMotobikeInsuranceDetail.tenantId
        }
        break;
      default:
        break;
    }
    return insuranceDetail
  }

  const setFormatDataInfo = (contractDetail) => {
    const { insuredMemberDetail, holderName, holderPhoneNumber, holderEmail, status, effective_date, expired_date } = contractDetail;
    const carOwner = insuredMemberDetail[0].vehicleOwnerName;
    const licensePlates = insuredMemberDetail[0].plateNumber.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, "");
    const carBrand = insuredMemberDetail[0].brand;
    const carModel = insuredMemberDetail[0].model;
    const chassisNo = insuredMemberDetail[0].chassisNumber === "00000000" ? "" : insuredMemberDetail[0].chassisNumber;
    const engineNo = insuredMemberDetail[0].engineNumber === "00000000" ? "" : insuredMemberDetail[0].engineNumber;
    const fullName = holderName;
    const phone = holderPhoneNumber;
    const email = holderEmail;
    const dateEffect = setDateEffect(status, effective_date, expired_date)
    return {
      carOwner, licensePlates, carBrand, carModel, chassisNo, engineNo,
      fullName, phone, email, dateEffect
    }
  }

  const goToCustomerInfoScreen = async () => {
    try {
      ZaloPaySDK.showLoading();
      const { carOwner, licensePlates, carBrand, carModel, chassisNo, engineNo,
        dateEffect, email, fullName, phone
      } = setFormatDataInfo(contractDetail);
      const data = {
        carOwner, licensePlates, carBrand, carModel, chassisNo, engineNo,
        fullName, phone, email, dateEffect
      }
      dispatch(updateCustomerInfo(data));
      const insuranceDetail = await formatInsuranceDetail(contractDetail);
      history.push({
        pathname: `${PATH}/customer-info`,
        state: {
          insuranceDetail: insuranceDetail,
          type: contractDetail.productType === PRODUCT_TYPE.motorbike_liability ? TYPE_INSURANCE.MOTOBIKE : contractDetail.productType,
          logo: contractDetail.companyLogo,
          title: contractDetail.productName,
          companyName: contractDetail.companyTranslations[1].name,
          totalAllSumAssured: Utils.formatMoneyVND(contractDetail.insuredMemberDetail[0].benefit[0].sumAssured),
          amount:
            (contractDetail.premium *
              (100 - (+contractDetail.discountRate) || 0)) /
            100,
          timeText: getStringDuration(
            contractDetail.billingType
          ).toLowerCase(),
        },
      })
    } catch (error) {
      console.log(error)
    } finally {
      ZaloPaySDK.hideLoading();
    }
  }

  const handlePayment = async () => {
    try {
      ZaloPaySDK.showLoading();
      const { productType, productId, holderDetail: { person: { address: { district_code, region_code, ward_code, street_address } } } } = contractDetail;
      const { carOwner, licensePlates, carBrand, carModel, chassisNo, engineNo,
        dateEffect, email, fullName, phone
      } = setFormatDataInfo(contractDetail);
      let type = "";
      let productTypePayoder = "";
      let insuranceDetail = { productId, yearType: 1 };
      let contract = {
        dateEffect, phone, email, fullName,
        city: { code: region_code },
        district: { code: district_code },
        ward: { code: ward_code },
        address: street_address
      };
      let vehicleInfo = {
        carOwner,
        licensePlates,
        chassisNo,
        engineNo
      };
      const recipient = {
        fullNameRecipient: "",
        phoneRecipient: "",
        addressRecipient: "",
        cityRecipient: {},
        districtRecipient: {},
        wardRecipient: {},
      }
      const key = type === TYPE_INSURANCE.CAR ? "_convertLicensePlatesCar" : "_convertLicensePlates";
      vehicleInfo.licensePlates = Utils[key](vehicleInfo.licensePlates);
      switch (productType) {
        case PRODUCT_TYPE.motorbike_liability:
          type = TYPE_INSURANCE.MOTOBIKE;
          productTypePayoder = PRODUCT_TYPE.motorbike_liability;
          break;
        case PRODUCT_TYPE.car:
          type = TYPE_INSURANCE.CAR;
          productTypePayoder = PRODUCT_TYPE.car;
          const detailsInsurance = await formatInsuranceDetail(contractDetail);
          insuranceDetail = detailsInsurance
          vehicleInfo = {
            ...vehicleInfo,
            carModel: { code: carModel },
            carBrand: { code: carBrand }
          }
          break;
        default:
          break;
      }
      dispatch(
        createOrderCallback(type, insuranceDetail, contract, vehicleInfo, recipient, (resp) => {
          const { id } = resp;
          dispatch(payOrder(id, productTypePayoder));
        })
      );
    } catch (error) {
      console.log(error)
    }
  }

  const renderContent = () => {
    if (contractDetail) {
      const isMotobike = contractDetail.productType === PRODUCT_TYPE.motorbike_liability;
      const isCar = contractDetail.productType === PRODUCT_TYPE.car;
      const isHome = contractDetail.productType === PRODUCT_TYPE.home;
      const latestEndorsementApproved = contractDetail.endorsement
        .find(
          (endorsement) =>
            endorsement.status === "approved" ||
            endorsement.status === "waiting_for_approve"
        );
      return (
        <>
          <InsuranceInfo
            typeContract={type}
            contract={contractDetail}
            viewCoverageBenefit={viewCoverageBenefit}
            viewDetailBinding={viewDetailBinding}
            latestEndorsementApproved={latestEndorsementApproved}
          />
          <ModalCoverageBenefit
            contract={contractDetail}
            isOpen={isOpenModal}
            closeModal={closeModal}
          />
          <ContractOwnerInfo
            typeContract={type}
            fullName={contractDetail.holderName}
            phone={contractDetail.holderPhoneNumber}
            email={contractDetail.holderEmail}
            address={contractDetail.holderAddress}
            ward={contractDetail.holderWardNameWithType}
            district={contractDetail.holderDistrictNameWithType}
            city={contractDetail.holderCityProvinceNameWithType}
            productType={contractDetail.productType}
          />
          {(isMotobike || isCar || isHome) ? (
            <>
              {
                (isMotobike || isCar) && <MotobikeInfo
                  carOwner={
                    contractDetail.insuredMemberDetail[0].vehicleOwnerName
                  }
                  licensePlate={contractDetail.insuredMemberDetail[0].plateNumber}
                />
              }
              <RecipientInfo
                typeContract={type}
                contract={contractDetail}
                quotationCode={quotationCode}
                mUid={userInfo.mUid}
              />
            </>
          ) : null}
          <div
            className="bg-white padding-v-12 padding-h-16"
            onClick={goToQAScreen}
          >
            <div className="flex-v-center flex-space-between">
              <div className="flex-v-center">
                <div className="qa-icon-left">
                  <img
                    src={images.icon_systems_glyph_info_tutorial}
                    alt="icon_systems"
                  />
                </div>
                <div className="qa-title color-black font-size-14 margin-left-12">
                  Câu hỏi thường gặp
                </div>
              </div>
              <div className="qa-icon-right">
                <img
                  src={images.icon_systems_other_arrow_right}
                  alt="icon_systems"
                />
              </div>
            </div>
          </div>
          <div className={`${type === "contract-detail" ? "margin-bottom-90" : ""}`}></div>
          <div className="button-fixed-bottom">
            <button
              className={`btn-update-info`}
              onClick={goToCustomerInfoScreen}
            >
              Cập nhật thông tin
            </button>
            <button
              className="btn-payment"
              onClick={handlePayment}
            >
              Thanh toán
            </button>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return <SafeAreaView>{renderContent()}</SafeAreaView>;
};

export default RenewalInsuranceDetailsScreen;
