import React, { forwardRef, Fragment, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import "../stylesComponents/InsuranceInfo.scss"
import Utils from 'utils/utils';
import { LIST_CAR_TYPE, TYPE_INSURANCE } from 'utils/const';
import Select from 'components/CarProduct/component/Select';
import FloatInput from 'components/FloatInput/FloatInput';
import { useDispatch, useSelector } from 'react-redux';
import { getInsuranceCarProduct, getInsuranceMotobikeProduct } from 'stores/insurance/insurance.action';
import ZaloPaySDK from 'utils/zalopay';
import { scrollToRefObject } from 'utils/helper';
import { useQuery } from 'screens/HomeScreen/HomeScreen';
import { useLocation } from "react-router-dom";

const InsuranceInfo = forwardRef((props, ref) => {
    const { productType, year } = props;
    const dispatch = useDispatch();
    const company = useQuery().get('company');
    const isCar = productType === TYPE_INSURANCE.CAR;
    const isMotobike = productType === TYPE_INSURANCE.MOTOBIKE;
    const { insuranceCarProduct, gettingInsuranceCarProduct, insuranceMotobikeProduct, gettingInsuranceMotobikeProduct } = useSelector((state) => state.insurance);
    const stateLocation = useLocation().state;

    const refScrollCarType = useRef();
    const refCarType = useRef();
    const refScrollSeats = useRef();
    const refSeat = useRef();
    const refScrollCompany = useRef();
    const refCompany = useRef();

    const [carType, setCarType] = useState({});
    const [isValidCarType, setIsValidCarType] = useState(false);
    const [seat, setSeat] = useState("");
    const [isValidSeat, setIsValidSeat] = useState(false);
    const [isSeatEmpty, setIsSeatEmpty] = useState(false);
    const [listCompany, setListCompany] = useState([]);
    const [itemCompany, setItemCompany] = useState({});
    const [isValidCompany, setIsValidCompany] = useState(false);
    const [feeInsurance, setFeeInsurance] = useState(0);
    const [isBlur, setIsBlur] = useState(false);
    const [insuranceDetail, setInsuranceDetail] = useState({});
    const [yearType, setYearType] = useState(+year || 1);

    const title = `Bảo hiểm bắt buộc TNDS của chủ xe ${isCar ? "ô tô" : "máy (từ 50cc trở lên)"}`;

    useEffect(() => {
        if(stateLocation && stateLocation._seat && Object.keys(stateLocation._carType).length) {
            setSeat(stateLocation._seat);
            setCarType(stateLocation._carType);
            window.history.replaceState({}, '')
        }
    }, [stateLocation])

    useEffect(() => {
        if (gettingInsuranceCarProduct || gettingInsuranceMotobikeProduct) {
          ZaloPaySDK.showLoading();
        } else {
          ZaloPaySDK.hideLoading();
        }
    }, [gettingInsuranceCarProduct, gettingInsuranceMotobikeProduct]);

    useEffect(() => {
        if(insuranceCarProduct && insuranceCarProduct.car.length) {
            setInsurance(insuranceCarProduct)
        }else if(insuranceMotobikeProduct && insuranceMotobikeProduct.motobike.length) {
            setInsurance(insuranceMotobikeProduct)
        }
    }, [insuranceCarProduct, insuranceMotobikeProduct])

    useEffect(() => {
        if(!stateLocation && Object.keys(carType).length && seat && !isValidSeat && isBlur && isCar) {
            const data = {
                usePurpose: carType.code.usePurpose,
                goodsCap: 0,
                carInfo: carType.code.carInfo+"",
                stateAmountInsurances: {},
                seat: +seat
            };
            dispatch(getInsuranceCarProduct({data}));
        }else if(isMotobike) {
            dispatch(getInsuranceMotobikeProduct({ yearType }));
        }
    }, [carType, seat, isBlur, productType, yearType])

    const setInsurance = (insurance) => {
        setListCompany(insurance[productType].map(m => {
            return {
                name: m.company.name,
                code: m.company.id+"",
                nameWithType: m.company.name
            }
        }))
        setFeeInsurance(insurance[productType][0].premium);
        props.getFee(insurance[productType][0].premium);

        if(company) {
            const itemInsurance = insurance[productType].find(f => f.company.code === company);
            const isCheck = itemInsurance && Object.keys(itemInsurance).length !== 0;
            isCheck && setItemCompany({
                name: itemInsurance.company.name,
                code: itemInsurance.company.id + ""
            });
            isCheck && props.getProductCode(itemInsurance.productCode)
            setInsuranceDetail(itemInsurance)
        }
    }
    
    const setCheckIsSeat = (carInfo, seat) => {
        if(carInfo === 19) {
            if(seat > 5 || seat < 2) {
                setIsValidSeat(true)
            }else {
                setIsValidSeat(false);
            }
        }else {
            if(seat > 50 || seat < 2) {
                setIsValidSeat(true)
            }else {
                setIsValidSeat(false);
            }
        }
    }

    const onChangeCarType = useCallback(
        (value) => {
            setCarType(value);
            setIsValidCarType(!value.code);
            if(value && seat) {
                setCheckIsSeat(value.code.carInfo, seat);
            }else {
                setIsValidSeat(false);
            }
        },
        [seat],
    )
    
    const onSeatChange = useCallback(
        (value) => {
            setSeat(value);
            setIsSeatEmpty(!value)
            setIsBlur(false);
            if(value && Object.keys(carType).length) {
                setCheckIsSeat(carType.code.carInfo, value);
            }else {
                setIsValidSeat(false);
            }
        },
        [carType],
    )
    
    const onEndEditingSeat = () => {
        setIsBlur(true);
    }

    const onChangeCompany = (value) => {
        setItemCompany(value);
        setIsValidCompany(!value.code);
        let insuranceDetails = {};
        switch (productType) {
            case TYPE_INSURANCE.MOTOBIKE:
                insuranceDetails = insuranceMotobikeProduct[productType].find(f => f.company.id + "" === value.code);
                break;
            case TYPE_INSURANCE.CAR:
                insuranceDetails = insuranceCarProduct[productType].find(f => f.company.id + "" === value.code);
                break;
            default:
                break;
        }
        props.getProductCode(insuranceDetails.productCode);
        setInsuranceDetail(insuranceDetails);
    }

    const handleChangeYearType = (e) => {
        setYearType(+e.target.value);
    }

    useImperativeHandle(
        ref, () => ({
            validInsuranceInfo() {
                setIsValidCarType(isCar ? !carType.code : false);
                setIsSeatEmpty(isCar ? !seat : false);
                setIsValidCompany(!itemCompany.code);
                if(isCar && !carType.code) {
                    scrollToRefObject(refScrollCarType);
                }
                if(isCar && !seat) {
                    scrollToRefObject(refScrollSeats);
                }
                if(!itemCompany.code) {
                    scrollToRefObject(refScrollCompany);
                }
                return isCar ? !!carType.code && !isValidSeat && !isSeatEmpty && !!itemCompany.code : !!itemCompany.code;
            },
            getInsuranceInfo() {
                if(isCar) {
                    return {
                        usePurpose: carType.code.usePurpose,
                        goodsCap: 0,
                        carInfo: carType.code.carInfo+"",
                        seat: +seat,
                    }
                }else if(isMotobike) {
                    return {
                        yearType
                    }
                }
            },
            getInsuranceDetail() {
                return insuranceDetail
            }
        })
    )

    return (
        <div className="insurance-info bg margin-top-40 margin-h-16 flex">
            <div className="bg-white flex pos-relative w-100 insurance-info-wrapper">
                <div className="title-insurance color-black">{title}</div>
                <div className="maximum-limit">
                    <p>Mức trách nhiệm dân sự về người thứ ba</p>
                    <p>- Về người: 150.000.000đ/người/vụ</p>
                    <p>- Về tài sản: 100.000.000đ/vụ</p>
                </div>
                {
                    isCar && <Fragment>
                        <div className="form_row" ref={refScrollCarType}>
                            <Select
                                ref={refCarType}
                                title={'Loại xe'}
                                placeholder={'Chọn loại xe'}
                                data={LIST_CAR_TYPE}
                                selectedValue={carType}
                                disabled={!LIST_CAR_TYPE}
                                onValueChange={onChangeCarType}
                                isSearch={false}
                                require
                                heightModal="30vh"
                                error={isValidCarType}
                            />
                        </div>
                        <div className="form_row" ref={refScrollSeats}>
                            <FloatInput
                                ref={refSeat}
                                label={isSeatEmpty ? "Vui lòng nhập số chỗ ngồi" : isValidSeat ? "Vượt quá giới hạn chỗ ngồi" : "Nhập số chỗ ngồi" }
                                type="number"
                                iconClear={true}
                                value={seat}
                                onChangeText={onSeatChange}
                                onEndEditing={onEndEditingSeat}
                                error={isValidSeat || isSeatEmpty}
                                require
                            />
                        </div>
                    </Fragment>
                }
                <div className="price-time" style={{marginTop: isMotobike ? 10 : ""}}>
                    <div className="title color-gray">Phí bảo hiểm: / {isMotobike ? yearType : 1} năm</div>
                    <div className="value color-blue">{Utils.formatMoneyVND(feeInsurance)}đ</div>
                </div>
                <div className="form_row" ref={refScrollCompany}>
                    <Select
                        ref={refCompany}
                        title={'Công ty bảo hiểm'}
                        placeholder={'Chọn công ty bảo hiểm'}
                        data={listCompany}
                        selectedValue={itemCompany}
                        disabled={!!company}
                        onValueChange={onChangeCompany}
                        error={isValidCompany}
                        heightModal="50vh"
                    />
                </div>
                {
                    isMotobike && <div className="form-row form-radio">
                        <p className="font font-size-14 color-gray" style={{flexGrow: 1}}>Thời hạn bảo hiểm</p>
                        {
                            [
                                {value: 1, name: "1 năm"},
                                {value: 2, name: "2 năm"},
                                {value: 3, name: "3 năm"}
                            ].map((m, i) => {
                                return <div className='input-radio' key={i}>
                                    <input
                                        disabled={!!year}
                                        id={m.value + "id" + i}
                                        type='checkbox'
                                        name={"ownership"}
                                        value={m.value}
                                        checked={yearType === m.value}
                                        onChange={handleChangeYearType}
                                    />
                                    <label disabled={!!year} htmlFor={m.value + "id" + i}>{m.name}</label>
                                </div>
                            })
                        }
                    </div>
                }
            </div>
        </div>
    )
});

export default React.memo(InsuranceInfo);