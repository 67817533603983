import React, { Fragment, useEffect, useMemo, useState } from "react";
import images from "../../utils/images";
import { Swiper } from "../../components/Swiper";
import "./HomeScreen.scss";
import ZaloPaySDK from "../../utils/zalopay";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getInsuranceAllProduct, getParamProductType, getParamProductTypeShareLink } from "../../stores/insurance/insurance.action";
import { APPID, APPID_CAR, TYPE_INSURANCE } from "../../utils/const";
import trackingId from "../../utils/tracking";
import { updateCustomerInfo } from "stores/customer/customer.action";
// import ZaloPayV2SDK from "utils/zalopayV2";

const listTab = [
  {
    label: "Tai nạn",
    value: TYPE_INSURANCE.ACCIDENT,
    icon: "icon_accident",
    code: "accident"
  },
  {
    label: "Xe máy",
    value: TYPE_INSURANCE.MOTOBIKE,
    icon: "icon_bike",
    code: "motobike"
  },
  {
    label: "Xe ô tô",
    value: TYPE_INSURANCE.CAR,
    icon: "icon_car",
    code: "car"
  },
  {
    label: "Nhà tư nhân",
    value: TYPE_INSURANCE.HOME,
    icon: "icon_home",
    code: "home"
  },
];

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const HomeScreen = () => {
  const tab = useQuery().get('product_type');
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(tab || TYPE_INSURANCE.ACCIDENT);
  const {
    gettingInsuranceAllProduct,
    insuranceAllProduct,
    getInsuranceAllProductErrMsg,
  } = useSelector((state) => state.insurance);
  const { customerInfo } = useSelector((state) => state.customer);
  useEffect(() => {
    const unListen = history.listen((newLocation, action) => {
      if (action !== "REPLACE") {
        unListen();
      }
      if (action === "POP") {
        if (ZaloPaySDK.isZaloPay()) {
          ZaloPaySDK.closeWindow();
        } else {
          history.go(1);
        }
      }
    });
  }, [history]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeTab]);

  // useEffect(() => {
  //   ZaloPayV2SDK.setTitle("Bảo hiểm");
  //   ZaloPayV2SDK.setDeviceAppInfo()
  //   .then(info => console.log(`ZLPSDK V2 Device.appInfo: ${JSON.stringify(info)}`))
  //   .catch(err => console.error('error V2 Device.appInfo', err))
  // }, []);

  useEffect(() => {
    dispatch(getInsuranceAllProduct());
    dispatch(getParamProductType({ param: tab }))
    dispatch(getParamProductTypeShareLink({ param_share_link: activeTab }));
    const { carOwner, phone, fullName, email, address, city, district, ward, licensePlates } = customerInfo;
    const data = { carOwner, phone, fullName, email, address, city, district, ward, licensePlates };
    dispatch(updateCustomerInfo(data));
  }, [dispatch]);

  useEffect(() => {
    if (gettingInsuranceAllProduct) {
      ZaloPaySDK.showLoading();
    } else {
      ZaloPaySDK.hideLoading();
    }
  }, [gettingInsuranceAllProduct]);

  useEffect(() => {
    if (getInsuranceAllProductErrMsg) {
      ZaloPaySDK.showDialog({
        message: getInsuranceAllProductErrMsg,
        buttons: [{ text: "Đóng" }],
      });
    }
  }, [getInsuranceAllProductErrMsg]);

  const changeTab = (tabActive) => {
    setActiveTab(tabActive)
    dispatch(getParamProductTypeShareLink({ param_share_link: tabActive }));
    if(activeTab !== tabActive) {
      if (tabActive === TYPE_INSURANCE.ACCIDENT) {
        ZaloPaySDK.tracking(trackingId.INSURANCE_LISTING.ACCIDENT_INSURANCE);
      }else if (tabActive === TYPE_INSURANCE.CAR) {
        ZaloPaySDK.tracking(trackingId.INSURANCE_LISTING.CAR_INSURANCE);
      }else if(tabActive === TYPE_INSURANCE.MOTOBIKE) {
        ZaloPaySDK.tracking(trackingId.INSURANCE_LISTING.TWO_WHEELER_INSURANCE);
      }
    }
    // ZaloPaySDK.showLoading();
    // setTimeout(() => {
    //   setActiveTab(tabActive)
    //   if(activeTab !== tabActive) {
    //     if (tabActive === TYPE_INSURANCE.ACCIDENT) {
    //       ZaloPaySDK.getAppUserInfoChangeTab(APPID, () => {})
    //       ZaloPaySDK.tracking(trackingId.INSURANCE_LISTING.ACCIDENT_INSURANCE);
    //     }else if (tabActive === TYPE_INSURANCE.CAR) {
    //       ZaloPaySDK.getAppUserInfoChangeTab(APPID_CAR, () => {})
    //       ZaloPaySDK.tracking(trackingId.INSURANCE_LISTING.CAR_INSURANCE);
    //     }else if(tabActive === TYPE_INSURANCE.MOTOBIKE) {
    //       ZaloPaySDK.getAppUserInfoChangeTab(APPID, () => {})
    //       ZaloPaySDK.tracking(trackingId.INSURANCE_LISTING.TWO_WHEELER_INSURANCE);
    //     }
    //   }
    //   ZaloPaySDK.hideLoading();
    // }, 1000);
  };

  const getBackgroundImage = (type) => {
    switch (type) {
      case TYPE_INSURANCE.ACCIDENT:
        return images.banner_accident;
      case TYPE_INSURANCE.MOTOBIKE:
        return images.banner_bike;
      case TYPE_INSURANCE.CAR:
      case TYPE_INSURANCE.HOME:
        return images.banner_accident;
      default:
        return;
    }
  };

  const getTitle = (type) => {
    switch (type) {
      case TYPE_INSURANCE.ACCIDENT:
        return "Mua bảo hiểm tai nạn";
      case TYPE_INSURANCE.MOTOBIKE:
        return "Mua bảo hiểm xe máy";
      case TYPE_INSURANCE.CAR:
        return "Mua bảo hiểm xe ô tô";
      case TYPE_INSURANCE.HOME:
        return "Mua bảo hiểm nhà tư nhân";
      default:
        return;
    }
  };

  const contact = useMemo(() => {
    switch (activeTab) {
      case "accident":
        return <Fragment>
          <div style={{textAlign: "justify"}} className="paragraph-center">Sản phẩm Bảo hiểm Trợ cấp nằm viện và tai nạn được cung cấp bởi công ty Bảo hiểm Liên Hiệp (UIC). Nhà phân phối và chịu trách nhiệm trực tiếp là đại lý bảo hiểm Công ty Cổ phần Savemoney (Savemoney)</div>
          <div style={{textAlign: "justify"}} className="paragraph-center" >ZION đóng vai trò là đơn vị cung cấp dịch vụ hỗ trợ hiển thị và trung gian thanh toán, hỗ trợ khách hàng truy cập và thanh toán Sản phẩm. Các vấn đề khác liên quan đến việc cung cấp và triển khai sản phẩm, được thực hiện theo Quy tắc, điều khoản của sản phẩm do UIC ban hành, và Savemoney là đầu mối tiếp nhận và xử lý với Khách hàng</div>
          <div style={{textAlign: "justify"}} className="paragraph-center">Bạn vui lòng kiểm tra, đảm bảo thông tin chính xác và các quy định nhà cung cấp trước khi thực hiện thanh toán</div>
        </Fragment>
      case "motobike":
        return <Fragment>
          <div style={{textAlign: "justify"}} className="paragraph-center">Sản phẩm Bảo hiểm bắt buộc TNDS của chủ xe mô tô - xe máy được cung cấp bởi Các công ty bảo hiểm.Nhà phân phối và chịu trách nhiệm trực tiếp là đại lý bảo hiểm Công ty Cổ phần Savemoney (Savemoney).</div>
          <div style={{textAlign: "justify"}} className="paragraph-center" >ZION đóng vai trò là đơn vị cung cấp dịch vụ hỗ trợ hiển thị và trung gian thanh toán, hỗ trợ khách hàng truy cập và thanh toán Sản phẩm.</div>
          <div style={{textAlign: "justify"}} className="paragraph-center" >Các vấn đề khác liên quan đến việc cung cấp và triển khai sản phẩm, được thực hiện theo Quy tắc, Điều khoản của sản phẩm do Các công ty bảo hiểm ban hành và Savemoney là đầu mối tiếp nhận và xử lý với Khách hàng.</div>
          <div style={{textAlign: "justify"}} className="paragraph-center">Bạn vui lòng kiểm tra, đảm bảo thông tin chính xác và tuân thủ các quy định  của nhà cung cấp trước khi thực hiện thanh toán.</div>
        </Fragment>
      default:
        break;
    }
  }, [activeTab]);

  const setImageBanner = () => {
    let url = "";
    if(activeTab === TYPE_INSURANCE.CAR) {
      url = images.zalopay_banner_car_v2;
    } else if(activeTab === TYPE_INSURANCE.MOTOBIKE) {
      url = images.zalopay_banner_xm_v2;
    } else {
      url = images.zalopay_banner_prod;
    }
    return url;
  }

  return (
    <div className="home-screen-container bg">
      <div>
        <img
          alt=""
          className='home-banner-zalo'
          src={setImageBanner()}
        />
      </div>
      <div className="flex bg-white header-home-page">
        {listTab.map((item) => (
          <div
            key={item.value}
            className={`home-tab flex-center w-100 ${activeTab === item.value ? "border-bottom-blue-2" : ""
              }`}
            onClick={() => changeTab(item.value)}
          >
            <img
              alt=""
              className={`icon-tab ${activeTab === item.value ? "" : "icon-tab-inactive"
                }`}
              src={images[item.icon]}
            />
            <div
              className={`font font-size-14 margin-top-4 ${activeTab === item.value ? "color-black" : "color-gray"
                }`}
            >
              {item.label}
            </div>
          </div>
        ))}
      </div>
      <div
        className="flex flex-column home-banner"
        style={{
          backgroundImage: `url(${getBackgroundImage(activeTab)})`,
        }}
      >
        <div className="large-title color-green font-weight-bold">
          {getTitle(activeTab)}
        </div>
        <div className="small-title font-weight-bold">
          với 3 bước nhanh chóng
        </div>
      </div>
      <div className="home-swiper">
        {
          insuranceAllProduct &&<Swiper
            insurances={insuranceAllProduct || {}}
            type={activeTab}
            // changeTab={changeTab}
          />
        }
      </div>
      {insuranceAllProduct && insuranceAllProduct[activeTab] && insuranceAllProduct[activeTab].length > 0 ? (
        <div className="color-gray text-align-center home-copyright">
          <div>
            <img src={images.icon_merchant_save_money_full} alt="" />
          </div>
          {contact}
          <div>
            Mọi thắc mắc xin liên hệ hotline{" "}
            <a href="tel:1900555518" className="color-black">
              1900 555 518
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default HomeScreen;
