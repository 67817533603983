import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
  useCallback,
} from "react";
import Util from "../../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import FloatInput from "../../../components/FloatInput/FloatInput";
import { scrollToRefObject } from "../../../utils/helper";
import { updateCustomerInfo } from "../../../stores/customer/customer.action";
import Select from "components/CarProduct/component/Select";
import { carData } from "components/CarProduct/mockup/CarData";
import { uniq } from "lodash";
import { ABIC_PRODUCT_CODES, HDI_PRODUCT_CODES, MIC_PRODUCT_CODES, TAS_PRODUCT_CODES } from "utils/const";
import { clearCarBrand, getCarBrands, getCarBrandsAndModelsTasco, getCarModels } from "stores/carInfomation/carInfomation.action";

const CarInput = forwardRef((props, ref) => {
  const { productCode } = props;

  const dispatch = useDispatch();
  const { customerInfo } = useSelector((state) => state.customer);
  const { userInfo } = useSelector((state) => state.appParams);
  const { brands, isLoading, isError } = useSelector(state => state.carInfomation);
  const [licensePlates, setLicensePlates] = useState(
    customerInfo && customerInfo.licensePlates && Util.validateLicensePlatesCarRemoveCharSpecial(customerInfo.licensePlates) ? customerInfo.licensePlates : ""
  );

  const [carOwner, setCarOwner] = useState(customerInfo && customerInfo.carOwner ? customerInfo.carOwner.toUpperCase() : "");

  const [chassisNo, setChassisNo] = useState(
    customerInfo && customerInfo.chassisNo ? customerInfo.chassisNo : ""
  );
  const [isValidChassisNo, setIsValidChassisNo] = useState(false);

  const [engineNo, setEngineNo] = useState(
    customerInfo && customerInfo.engineNo ? customerInfo.engineNo : ""
  );
  const [isValidEngineNo, setIsValidEngineNo] = useState(false);

  const [carBrandData, setCarBrandData] = useState([]);
  const [carModelData, setCarModelData] = useState([]);

  const [carBrand, setCarBrand] = useState(
    customerInfo && customerInfo.carBrand ? customerInfo.carBrand : ""
  );
  const [isValidCarBrand, setIsValidCarBrand] = useState(false);

  const [carModel, setCarModel] = useState(
    customerInfo && customerInfo.carModel ? customerInfo.carModel : ""
  );
  const [isValidCarModel, setIsValidCarModel] = useState(false);

  const [isValidLicensePlates, setIsValidLicensePlates] = useState(true);
  const [isValidCharSpecial, setIsValidCharSpecial] = useState(true);
  const [isValidCarOwner, setIsValidCarOwner] = useState(true);

  const refCarOwner = useRef();
  const refLicensePlates = useRef();
  const refChassisNo = useRef();
  const refEngineNo = useRef();
  const refCarBrand = useRef();
  const refCarModel = useRef();
  const refScrollLicensePlates = useRef();

  useEffect(() => {
    customerInfo.licensePlates = licensePlates;
    customerInfo.carOwner = carOwner;
    customerInfo.chassisNo = chassisNo;
    customerInfo.engineNo = engineNo;
    customerInfo.carBrand = carBrand;
    customerInfo.carModel = carModel;
    dispatch(updateCustomerInfo(customerInfo));
  }, [customerInfo, dispatch, licensePlates, carOwner, carBrand, carModel, chassisNo, engineNo]);

  useEffect(() => {
    if(isError) {
      setCarBrandData([]);
      refCarBrand.current.closeModal();
      refCarModel.current.closeModal();
    }
  }, [isError])

  useEffect(() => {
    if(MIC_PRODUCT_CODES.includes(productCode)) {
      dispatch(getCarBrands());
    }else if(TAS_PRODUCT_CODES.includes(productCode)) {
      dispatch(getCarBrandsAndModelsTasco());
    }else {
      dispatch(clearCarBrand());
    }
  }, [productCode]);

  useEffect(() => {
    if(brands.length > 0) {
      if(MIC_PRODUCT_CODES.includes(productCode)) {
        setCarBrandData(brands.map(m => {
          return {
            code: m,
            name: m,
            nameWithType: m
          }
        }))
      }else if(TAS_PRODUCT_CODES.includes(productCode)) {
        setCarBrandData(brands.map(m => {
          return {
            code: m.code,
            name: m.name,
            nameWithType: m.name
          }
        }))
      }
    }else {
      const brands = uniq(carData?.map((it) => it?.brand));
      const carBrandData = brands.map(item => ({
        code: item,
        name: item,
        nameWithType: item
      }));
      setCarBrandData(carBrandData);
    }
  }, [brands])
  
  const onChangeEvent = (value) => {
    setLicensePlates(value.toUpperCase());
  };

  const onEndEditing = () => {
    if(Util.validateCharSpecial(licensePlates)) {
      setIsValidCharSpecial(false);
      setIsValidLicensePlates(true);
    }else if(!Util.validateLicensePlatesCarRemoveCharSpecial(licensePlates)) {
      setIsValidLicensePlates(false);
      setIsValidCharSpecial(true);
    }else {
      setIsValidLicensePlates(true);
      setIsValidCharSpecial(true);
    }
  }

  const onClearValueEvent = () => {
    setLicensePlates("");
  };

  const getVehicleInfo = () => {
    return {
      carOwner,
      licensePlates,
      carBrand,
      carModel,
      chassisNo,
      engineNo,
    };
  };

  const onNameChange = (value) => {
    const valid = Util.validateName(value);
    setIsValidCarOwner(valid);
    setCarOwner(value);
  };

  const onChassisNoChange = (value) => {
    const nameValue = Util.toTitleCase(value);
    if(value) {
      const valid = Util.validateChassisNo(nameValue);
      setIsValidChassisNo(valid);
    }else {
      setIsValidChassisNo(false);
    }
    setChassisNo(nameValue);
  };

  const onEngineNoChange = (value) => {
    const nameValue = Util.toTitleCase(value);
    if(value) {
      const valid = Util.validateEngineNo(nameValue);
      setIsValidEngineNo(valid);
    }else {
      setIsValidEngineNo(false);
    }
    setEngineNo(nameValue);
  };

  const onChangeCarBrand = (value) => {
    console.log(value)
    setCarBrand(value);
    setIsValidCarBrand(false);
    setCarModel({});
    if(MIC_PRODUCT_CODES.includes(productCode)) {
      refCarModel.current.openModal();
      dispatch(getCarModels({brand: value.code}, (resp) => {
        setCarModelData(resp.map(m => ({
          code: m,
          name: m,
          nameWithType: m
        })));
      }));
    }else if(TAS_PRODUCT_CODES.includes(productCode)) {
      refCarModel.current.openModal();
      const carModelData = brands.find(f => f.code === value.code).models;
      setCarModelData(carModelData.map(m => ({
        code: m.code,
        name: m.name,
        nameWithType: m.name
      })));
    }else {
      refCarModel.current.openModal();
      const carModel = uniq(
        carData
          ?.filter((it) => it?.brand === value.code)
          ?.map((el) => el?.model)
      );
      if (value.code === 'Khác') {
        setCarModel({
          code: carModel[0],
          name: carModel[0],
          nameWithType: carModel[0]
        });
        setIsValidCarModel(false);
      } else {
        setCarModel({});
      }
      const carModelData = carModel.map(item => ({
        code: item,
        name: item,
        nameWithType: item
      }));
      setCarModelData(carModelData);
    }
  }

  const onChangeCarModel = (value) => {
    setCarModel(value);
    setIsValidCarModel(false);
  }

  const setValidateInfo = (validCarOwner, isValidLicensePlates, validChassisNo, validEngineNo) => {
    if([...HDI_PRODUCT_CODES, ...MIC_PRODUCT_CODES].includes(productCode)) {
      return isValidLicensePlates && validCarOwner && !!carBrand.code && !!carModel.code && !!chassisNo && !!engineNo && !!isValidCharSpecial && !validChassisNo && !validEngineNo;
    }else {
      return isValidLicensePlates && validCarOwner && !!carBrand.code && !!carModel.code && !!isValidCharSpecial && !validChassisNo && !validEngineNo
    }
  }

  useImperativeHandle(ref, () => ({
    validateInfo() {
      const isValidLicensePlates = Util.validateLicensePlatesCarRemoveCharSpecial(licensePlates);
      setIsValidLicensePlates(isValidLicensePlates);
      const isValidCharSpecial = Util.validateCharSpecial(licensePlates);
      setIsValidCharSpecial(!isValidCharSpecial);
      let validCarOwner = Util.validateName(carOwner);
      setIsValidCarOwner(validCarOwner);
      setIsValidCarBrand(!carBrand.code);
      setIsValidCarModel(!carModel.code);
      if (!isValidLicensePlates || isValidCharSpecial) {
        scrollToRefObject(refScrollLicensePlates);
      }
      if (!validCarOwner) {
        scrollToRefObject(refCarOwner);
      }
      if (!carBrand.code) {
        scrollToRefObject(refCarBrand);
      }
      if (!carModel.code) {
        scrollToRefObject(refCarModel);
      }
      let validChassisNo = false;
      let validEngineNo = false;
      if([...HDI_PRODUCT_CODES, ...MIC_PRODUCT_CODES].includes(productCode)) {
        validChassisNo = !chassisNo || Util.validateChassisNo(chassisNo);
        validEngineNo = !engineNo || Util.validateEngineNo(engineNo)
        setIsValidChassisNo(validChassisNo);
        setIsValidEngineNo(validEngineNo);
        if (!chassisNo) {
          scrollToRefObject(refChassisNo);
        }
        if (!engineNo) {
          scrollToRefObject(refEngineNo);
        }
      }
      else {
        if(chassisNo || engineNo) {
          validChassisNo = Util.validateChassisNo(chassisNo);
          validEngineNo = Util.validateEngineNo(engineNo);
          setIsValidChassisNo(validChassisNo);
          setIsValidEngineNo(validEngineNo);
        }
        if(!chassisNo && !engineNo) {
          setIsValidChassisNo(validChassisNo);
          setIsValidEngineNo(validEngineNo);
        }
      }
      return setValidateInfo(validCarOwner, isValidLicensePlates, validChassisNo, validEngineNo);
    },
    validateInfoByPhone() {
      const isValidLicensePlates = Util.validateLicensePlatesCarRemoveCharSpecial(licensePlates);
      setIsValidLicensePlates(isValidLicensePlates);
      const isValidCharSpecial = Util.validateCharSpecial(licensePlates);
      setIsValidCharSpecial(!isValidCharSpecial);
      let validCarOwner = Util.validateName(carOwner);
      setIsValidCarOwner(validCarOwner);
      setIsValidCarBrand(!carBrand.code);
      setIsValidCarModel(!carModel.code);

      return setValidateInfo(validCarOwner, isValidLicensePlates);
    },
    getVehicleInfo() {
      return getVehicleInfo();
    },
  }));

  const setLabelError = useCallback(() => {
    let labelError = "";
    if(isValidLicensePlates && isValidCharSpecial) {
      labelError = "Biển số xe. VD: 29C12345";
    }else {
      if(!isValidCharSpecial) {
        labelError = "Biển số xe chỉ được nhập chữ và số vd 29C12345";
      }else if(!isValidLicensePlates) {
        labelError = Util.invalidInfo("licensePlates");
      }
    }
    return labelError;
  },[isValidLicensePlates, isValidCharSpecial]);

  return (
    <>
      <div className="form_title">
        <div>Thông tin xe</div>
      </div>
      <div className="form_row" ref={refScrollLicensePlates}>
        <FloatInput
          ref={refCarOwner}
          id="refCarOwner"
          label={
            isValidCarOwner ? "Họ và tên chủ xe" : Util.invalidInfo("fullName")
          }
          value={carOwner}
          iconClear={true}
          textTransform={"uppercase"}
          error={!isValidCarOwner}
          onChangeText={onNameChange}
          require
        />
      </div>
      <div className="form_row" ref={refScrollLicensePlates}>
        <FloatInput
          ref={refLicensePlates}
          label={setLabelError()}
          value={licensePlates}
          iconClear={true}
          error={!isValidLicensePlates || !isValidCharSpecial}
          onChangeEvent={onChangeEvent}
          // onKeyDownEvent={onKeyDownEvent}
          onClearValueEvent={onClearValueEvent}
          onEndEditing={onEndEditing}
          require
        />
      </div>
      <div className="form_row" ref={refScrollLicensePlates}>
        <Select
          ref={refCarBrand}
          style={{ marginBottom: 16 }}
          title={'Hãng xe'}
          placeholder={
            !isValidCarBrand
              ? "Chọn hãng xe"
              : Util.invalidInfo("carBrand")
          }
          data={carBrandData}
          error={isValidCarBrand}
          selectedValue={carBrand}
          onValueChange={onChangeCarBrand}
          require
          heightModal="50vh"
          isLoading={isLoading}
          disabled={!productCode}
        >
        </Select>
      </div>
      <div className="form_row" ref={refScrollLicensePlates}>
        <Select
          ref={refCarModel}
          style={{ marginBottom: 16 }}
          title={'Hiệu xe'}
          placeholder={
            !isValidCarModel
              ? "Chọn hiệu xe"
              : Util.invalidInfo("carModel")
          }
          data={carModelData}
          error={isValidCarModel}
          selectedValue={carModel}
          onValueChange={onChangeCarModel}
          require
          heightModal="50vh"
          isLoading={isLoading}
          disabled={!productCode}
        >
        </Select>
      </div>
      <div className="form_row" ref={refScrollLicensePlates}>
        <FloatInput
          ref={refChassisNo}
          id="refChassisNo"
          label={
            !isValidChassisNo
              ? "Số khung"
              : Util.invalidInfo("chassisNo")
          }
          value={chassisNo}
          iconClear={true}
          error={isValidChassisNo}
          require={[...HDI_PRODUCT_CODES, ...MIC_PRODUCT_CODES].includes(productCode)}
          onChangeText={onChassisNoChange}
        />
      </div>
      <div className="form_row" ref={refScrollLicensePlates}>
        <FloatInput
          ref={refEngineNo}
          id="refEngineNo"
          label={
            !isValidEngineNo
              ? "Số máy"
              : Util.invalidInfo("engineNo")
          }
          value={engineNo}
          iconClear={true}
          error={isValidEngineNo}
          require={[...HDI_PRODUCT_CODES, ...MIC_PRODUCT_CODES].includes(productCode)}
          onChangeText={onEngineNoChange}
        />
      </div>
    </>
  );
});

export default React.memo(CarInput);
