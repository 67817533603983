import React from 'react';
import "./Contact.scss";
import images from 'utils/images';
import { useLocation } from "react-router-dom";
import ZaloPaySDK from 'utils/zalopay';
import { PATH } from 'utils/const';

const Contact = () => {
  const { pathname } = useLocation();

  if([`${PATH}/renewal`].includes(pathname) || pathname.search(`${PATH}/external-contract-detail`) !== -1 || pathname.search(`${PATH}/renewal-insurance-details`) !== -1) {
    return <div></div>
  }

  return (
    <div className="btn-contact" style={{
      bottom: [`${PATH}/home`, `${PATH}/management`].includes(pathname) ? 55 : 80,
    }}>
        <button
          onClick={() => {
            // window.open("https://zalo.me/savemoneyvn", "_blank");
            ZaloPaySDK.openExternalBrowser("https://zalo.me/savemoneyvn")
          }}
        >
          <img
            alt=""
            src={images.icon_contact}
          />
          <p>Liên hệ SaveMoney</p>
        </button>
    </div>
  )
}

export default Contact