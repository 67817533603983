import { types } from "./insurance.type";

export const getPersonalAccidentInsuranceDetail = ({ planId, langId }) => {
  return {
    type: types.GETTING_PERSONAL_ACCIDENT_INSURANCE_DETAIL,
    payload: {
      planId,
      langId,
    },
  };
};

export const getMotobikeInsuranceDetail = ({ productId, langId }) => {
  return {
    type: types.GETTING_MOTOBIKE_INSURANCE_DETAIL,
    payload: {
      productId,
      langId,
    },
  };
};

export const getCarInsuranceDetail = ({ productId, langId, data }) => {
  return {
    type: types.GETTING_CAR_INSURANCE_DETAIL,
    payload: {
      productId,
      langId,
      data
    },
  };
};

export const getHomeInsuranceDetail = ({ productId, langId }) => {
  return {
    type: types.GETTING_HOME_INSURANCE_DETAIL,
    payload: {
      productId,
      langId,
    },
  };
};

export const getInsuranceAllProduct = () => {
  return {
    type: types.GETTING_INSURANCE_ALL_PRODUCT,
  };
};

export const getInsuranceMotobikeProduct = ({ yearType }) => {
  return {
    type: types.GETTING_INSURANCE_MOTOBIKE_PRODUCT,
    payload: {
      yearType
    },
  }
}

export const getInsuranceCarProduct = ({ data }) => {
  return {
    type: types.GETTING_INSURANCE_CAR_PRODUCT,
    payload: data
  }
}

export const getInsuranceHomeProduct = ({ data }) => {
  return {
    type: types.GETTING_INSURANCE_HOME_PRODUCT,
    payload: data
  }
}

export const getParamProductType = ({ param }) => {
  return {
    type: "PARAM_TYPE_PRODUCT",
    payload: param
  }
}

export const getParamProductTypeShareLink = ({ param_share_link }) => {
  return {
    type: "PARAM_TYPE_PRODUCT_SHARE_LINK",
    payload: param_share_link
  }
}
