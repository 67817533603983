import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import AddressInput from "./AddressInput/AddressInput";
import {getDistricts, getWards} from "../../../stores/address/address.action";

const ChooseAddress = ({data, valid, onchange, refInput, isRenewal}) => {
  const dispatch = useDispatch();
  const {cities} = useSelector(state => state.address);
  const [districts, setDistricts] = useState(null);
  const [wards, setWards] = useState(null);

  const refCity = useRef();
  const refDistrict = useRef();
  const refWard = useRef();

  useEffect(() => {
    if(!isRenewal) {
      if (data.city.code) {
        getDataDistricts(data.city.code, refInput)
      }
      if (data.city.code && data.district.code) {
        getDataWards(data.city.code, data.district.code, refInput);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.city, data.district, isRenewal]);

  const getDataDistricts = (city, refInput) => {
    dispatch(getDistricts({city: city}, (value) => {
      setDistricts(value);
      refInput && refInput.current.openModal();
    }));
  };
  const getDataWards = (city, district, refInput) => {
    dispatch(getWards({city: city, district: district}, (value) => {
      setWards(value);
      refInput && refInput.current.openModal();
    }));
  };

  const onCityChange = (value) => {
    setDistricts(null);
    setWards(null);
    onchange && onchange({city: value, district: '', ward: ''}, refDistrict);
  };

  const onDistrictChange = (value) => {
    setWards(null);
    onchange && onchange({city: data.city, district: value, ward: ''}, refWard);
  };

  const onWardChange = (value) => {
    // console.log('onDistrictChange: ',value);
    onchange && onchange({city: data.city, district: data.district, ward: value});
  };

  return (
    <div style={{width: '100%'}}>
      <AddressInput
        ref={refCity}
        style={{marginBottom: 16}}
        title={'Chọn tỉnh thành phố'}
        placeholder={'Tỉnh thành phố'}
        selectedValue={data.city}
        error={!valid.isValidCity}
        data={cities ? cities : []}
        disabled={!cities}
        onValueChange={onCityChange}
        require
      >
      </AddressInput>

      {
        !isRenewal && <Fragment>
          <AddressInput
            ref={refDistrict}
            style={{marginBottom: 16}}
            title={'Chọn quận huyện'}
            placeholder={'Quận huyện'}
            selectedValue={data.district}
            error={!valid.isValidDistrict}
            data={districts ? districts : []}
            disabled={!data.city || !districts}
            onValueChange={onDistrictChange}
            require
          >
          </AddressInput>

          <AddressInput
            ref={refWard}
            style={{marginBottom: 16}}
            title={'Chọn phường xã'}
            placeholder={'Phường xã'}
            selectedValue={data.ward}
            error={!valid.isValidWard}
            data={wards ? wards : []}
            disabled={!data.district || !wards}
            onValueChange={onWardChange}
            require
          >
          </AddressInput>
        </Fragment>
      }

    </div>
  )
};

export default React.memo(ChooseAddress);