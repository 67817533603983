import { put, takeLatest } from "redux-saga/effects";
import { AppApiInstance } from "../../api/app-api";
import { types } from "./insurance.type";
import { ERROR_MESSAGES, TYPE_INSURANCE } from "../../utils/const";
import ZaloPaySDK from "../../utils/zalopay";
import trackingId from "../../utils/tracking";
import { dataCar } from "components/CarProduct/mockup/Car";
import { dataHouse } from "components/HouseProduct/mockup/HouseData";

function* getPersonalAccidentInsuranceDetail({ payload }) {
  try {
    const { langId, planId } = payload;
    const { data } = yield AppApiInstance.getPersonalAccidentInsuranceDetail({
      planIds: planId,
      langId,
    });
    ZaloPaySDK.tracking(trackingId.POLICY_DETAIL.LOAD_PAGE_SUCCESSFULLY);
    yield put({
      type: types.GET_PERSONAL_ACCIDENT_INSURANCE_DETAIL_SUCCESS,
      personalAccidentInsuranceDetail: data?.getHealthInsuranceDetail[0],
    });
  } catch (err) {
    yield put({
      type: types.GET_PERSONAL_ACCIDENT_INSURANCE_DETAIL_FAIL,
      errMsg: ERROR_MESSAGES.NORMAL,
    });
  }
}

function* getMotobikeInsuranceDetail({ payload }) {
  try {
    const { langId, productId } = payload;

    const data = yield AppApiInstance.getMotobikeInsuranceDetail({
      productId,
      langId,
    });

    yield put({
      type: types.GET_MOTOBIKE_INSURANCE_DETAIL_SUCCESS,
      motobikeInsuranceDetail: data?.data?.getMotobikeInsuranceDetail,
    });
    ZaloPaySDK.tracking(trackingId.POLICY_DETAIL.LOAD_PAGE_SUCCESSFULLY);
  } catch (err) {
    yield put({
      type: types.GET_MOTOBIKE_INSURANCE_DETAIL_FAIL,
      errMsg: ERROR_MESSAGES.NORMAL,
    });
  }
}

function* getCarInsuranceDetail({ payload }) {
  try {
    const { langId, productId, data } = payload;
    const { seat, goodsCap, carInfo, usePurpose, stateAmountInsurances, id } = data;

    const result = yield AppApiInstance.getMotobikeInsuranceDetail({
      productId,
      langId,
    });
    
    const resultDataFeeCar = yield AppApiInstance.getCarInsuranceFee({
      langId,
      type: "1",
      seat,
      goodsCap,
      carInfo,
      usePurpose,
      productIds: id
    });

    const products = resultDataFeeCar.data.getCarInsuranceFee.products.map(product => {
      let data = { ...product }
      if(!stateAmountInsurances.code) {
        let benefitCarCD11 = data.benefits.find(f => f.benefitCode === 'Car-CD11');
        data.benefits = data.benefits.filter(f => f.benefitCode !== 'Car-CD11');
        data.premium = data.premium - benefitCarCD11.premium;
      }
      return ({ ...data })
    });

    const dataFeeCar = {
      ...resultDataFeeCar.data.getCarInsuranceFee,
      products,
      premiums: products[0].premium, 
    }

    yield put({
      type: types.GET_CAR_INSURANCE_DETAIL_SUCCESS,
      carInsuranceDetail: {
        ...result?.data?.getMotobikeInsuranceDetail,
        dataFeeCar,
        seat, goodsCap, carInfo, usePurpose
      },
    });
    ZaloPaySDK.tracking(trackingId.POLICY_DETAIL.LOAD_PAGE_SUCCESSFULLY);
  } catch (err) {
    yield put({
      type: types.GET_CAR_INSURANCE_DETAIL_FAIL,
      errMsg: ERROR_MESSAGES.NORMAL,
    });
  }
}

function* getHomeInsuranceDetail({ payload }) {
  try {
    const { langId, productId } = payload;

    const data = yield AppApiInstance.getHomeInsuranceDetail({
      productId,
      langId,
    });

    yield put({
      type: types.GET_HOME_INSURANCE_DETAIL_SUCCESS,
      homeInsuranceDetail: data?.data?.getHomeInsuranceDetail,
    });
    ZaloPaySDK.tracking(trackingId.POLICY_DETAIL.LOAD_PAGE_SUCCESSFULLY);
  } catch (err) {
    yield put({
      type: types.GET_HOME_INSURANCE_DETAIL_FAIL,
      errMsg: ERROR_MESSAGES.NORMAL,
    });
  }
}

function* getInsuranceAllProduct() {
  const langId = "vi";
  try {
    const dataMotobikeInsuranceAllProduct = yield AppApiInstance.getMotobikeInsuranceAllProduct(
      {
        langId,
        type: 5,
      }
    );
    const dataPersonalAccidentInsuranceAllProduct = yield AppApiInstance.getPersonalAccidentInsuranceAllProduct(
      {
        langId,
        dob: "2000-01-01",
        insuranceAmount: 2000000000,
        type: 3,
      }
    );
    
    const personalAccidentInsurance =
      dataPersonalAccidentInsuranceAllProduct?.data
        ?.getPersonalAccidentInsuranceAllProduct?.products?.map(product => ({ ...product, type: TYPE_INSURANCE.ACCIDENT }));
    
    const motobikeInsurance =
      dataMotobikeInsuranceAllProduct.data?.getMotobikeInsuranceAllProduct
        ?.products?.map(product => ({ ...product, type: TYPE_INSURANCE.MOTOBIKE }));

    const carInsurance = dataCar.data?.getCarInsuranceAllProduct?.products?.map(product => ({ ... product, type: TYPE_INSURANCE.CAR}));

    const homeInsurance = dataHouse.data?.getHomeInsuranceAllProduct?.products?.map(product => ({ ... product, type: TYPE_INSURANCE.HOME}))

    // const dataInsuranceAllProduct = process.env.REACT_APP_ENVIRONMENT === "production" ? {
    //   [TYPE_INSURANCE.ACCIDENT]: personalAccidentInsurance,
    //   [TYPE_INSURANCE.MOTOBIKE]: motobikeInsurance,
    // } : {
    //   [TYPE_INSURANCE.ACCIDENT]: personalAccidentInsurance,
    //   [TYPE_INSURANCE.MOTOBIKE]: motobikeInsurance,
    //   [TYPE_INSURANCE.CAR]: carInsurance
    // }

    const dataInsuranceAllProduct = {
      [TYPE_INSURANCE.ACCIDENT]: personalAccidentInsurance,
      [TYPE_INSURANCE.MOTOBIKE]: motobikeInsurance,
      [TYPE_INSURANCE.CAR]: carInsurance,
      [TYPE_INSURANCE.HOME]: homeInsurance
    }

    ZaloPaySDK.tracking(trackingId.INSURANCE_LISTING.LOAD_PAGE_SUCCESSFULLY);
    yield put({
      type: types.GET_INSURANCE_ALL_PRODUCT_SUCCESS,
      insuranceAllProduct: dataInsuranceAllProduct,
    });
  } catch (err) {
    yield put({
      type: types.GET_INSURANCE_ALL_PRODUCT_FAIL,
      errMsg: ERROR_MESSAGES.NORMAL,
    });
  }
}

function* getInsuranceMotobikeProduct({ payload }) {
  const langId = "vi";
  const { yearType } = payload
  try {
    const dataMotobikeInsuranceAllProduct = yield AppApiInstance.getMotobikeInsuranceAllProduct(
      {
        langId,
        type: 5,
        yearType: yearType || 1
      }
    );
    
    const motobikeInsurance =
      dataMotobikeInsuranceAllProduct.data?.getMotobikeInsuranceAllProduct
        ?.products?.map(product => ({ ...product, type: TYPE_INSURANCE.MOTOBIKE})); 

    ZaloPaySDK.tracking(trackingId.INSURANCE_LISTING.LOAD_PAGE_SUCCESSFULLY);
    yield put({
      type: types.GET_INSURANCE_MOTOBIKE_PRODUCT_SUCCESS,
      insuranceMotobikeProduct: {
        [TYPE_INSURANCE.MOTOBIKE]: motobikeInsurance
      }
    })
  } catch (err) {
    yield put({
      type: types.GET_INSURANCE_MOTOBIKE_PRODUCT_FAIL,
      errMsg: ERROR_MESSAGES.NORMAL,
    });
  }
}

function* getInsuranceCarProduct({ payload }) {
  const langId = "vi";
  const {seat, goodsCap, carInfo, usePurpose, stateAmountInsurances} = payload;
  
  try {
    const dataCarInsuranceProduct = yield AppApiInstance.getCarInsuranceAllProduct({langId, type:"1", seat, goodsCap, carInfo, usePurpose});
    
    const carInsurance =
      dataCarInsuranceProduct.data?.getBasicCarInsuranceFeeOfAllProducts
        ?.products?.map(product => {
          let data = { ...product }
          if(!stateAmountInsurances.code) {
            let benefitCarCD11 = data.benefits.find(f => f.benefitCode === 'Car-CD11');
            data.benefits = data.benefits.filter(f => f.benefitCode !== 'Car-CD11');
            data.premium = data.premium - benefitCarCD11.premium;
          }
          return ({ ...data, type: TYPE_INSURANCE.CAR, seat, goodsCap, carInfo, usePurpose })
        });
    
    ZaloPaySDK.tracking(trackingId.INSURANCE_LISTING.LOAD_PAGE_SUCCESSFULLY);
    yield put({
      type: types.GET_INSURANCE_CAR_PRODUCT_SUCCESS,
      insuranceCarProduct: {
        [TYPE_INSURANCE.CAR]: carInsurance
      }
    })
  } catch (err) {
    yield put({
      type: types.GET_INSURANCE_CAR_PRODUCT_FAIL,
      errMsg: ERROR_MESSAGES.NORMAL,
    });
  }
}

const caculatorPremiumHomeInsurance = (benefits, marketPrice, numberOfYearsUsed) => {
  let premiums = 0;
  benefits.map(m => {
    let premiumsBenefit = 0;
    let caculator = m.rate.find(f2 => numberOfYearsUsed >= f2.premiumRule.age_min && numberOfYearsUsed <= f2.premiumRule.age_max);
    premiumsBenefit = premiumsBenefit + marketPrice * +caculator.premiumRule.premium_formula.split("*")[0] + (marketPrice * +caculator.premiumRule.premium_formula.split("*")[0] * 0.1);

    premiums += premiumsBenefit;
  })
  return premiums
}

function* getInsuranceHomeProduct({ payload }) {
  const langId = "vi";
  const { marketPrice, numberOfYearsUsed } = payload;
  try {
    const dataHomeInsuranceProduct = yield AppApiInstance.getHomeInsuranceAllProduct({langId, type: 7});
    const homeInsurance = dataHomeInsuranceProduct.data?.getHomeInsuranceAllProduct?.products?.map(product => {
      return {
        ...product,
        marketPrice,
        numberOfYearsUsed,
        premium: caculatorPremiumHomeInsurance(product.benefits, +marketPrice, numberOfYearsUsed)
      }
    });
    yield put({
      type: types.GET_INSURANCE_HOME_PRODUCT_SUCCESS,
      insuranceHomeProduct: homeInsurance
    });
  } catch (err) {
    yield put({
      type: types.GET_INSURANCE_HOME_PRODUCT_FAIL,
      errMsg: ERROR_MESSAGES.NORMAL,
    });
  }
}

export function* insuranceWatcher() {
  yield takeLatest(
    types.GETTING_PERSONAL_ACCIDENT_INSURANCE_DETAIL,
    getPersonalAccidentInsuranceDetail
  );
  yield takeLatest(
    types.GETTING_MOTOBIKE_INSURANCE_DETAIL,
    getMotobikeInsuranceDetail
  );
  yield takeLatest(
    types.GETTING_CAR_INSURANCE_DETAIL,
    getCarInsuranceDetail
  );
  yield takeLatest(
    types.GETTING_HOME_INSURANCE_DETAIL,
    getHomeInsuranceDetail
  );
  yield takeLatest(types.GETTING_INSURANCE_ALL_PRODUCT, getInsuranceAllProduct);
  yield takeLatest(types.GETTING_INSURANCE_MOTOBIKE_PRODUCT, getInsuranceMotobikeProduct);
  yield takeLatest(types.GETTING_INSURANCE_CAR_PRODUCT, getInsuranceCarProduct);
  yield takeLatest(types.GETTING_INSURANCE_HOME_PRODUCT,getInsuranceHomeProduct);
}
