import { put, takeLatest } from "redux-saga/effects";
import { AppApiInstance } from "../../api/app-api";
import { types } from "./payment.type";
import { ERROR_MESSAGES, TYPE_INSURANCE, APPID, APPID_CAR, PRODUCT_TYPE, APPID_HOME } from "../../utils/const";
import { createAccidentAdapter, createCarAdapter, createHomeAdapter, createMotobikeAdapter, createPolicyDraftAdapter } from "../../utils/helper";
import { store } from "./../configStore";

function* createOrder({ payload }) {
  // tạo hợp đồng, create policy
  try {
    const {
      type,
      insuranceDetail,
      customerInfo,
      vehicleInfo,
      homeInfo,
      recipient,
    } = payload;
    let responseCreateOrder,
      insurance = insuranceDetail,
      createOrderData = null;
    const { userInfo } = store.getState().appParams;
    if (type === TYPE_INSURANCE.ACCIDENT) {
      // bảo hiểm tai nạn
      if (insuranceDetail.planId) {
        // trường hợp từ trang chủ ( list bảo hiểm ) cần get detail bảo hiểm để post lên lại
        const resInsuredMemberDetail = yield AppApiInstance.getPersonalAccidentInsuranceDetail(
          { planIds: insuranceDetail.planId, langId: "vi" }
        );
        insurance = resInsuredMemberDetail.data
          .getHealthInsuranceDetail[0];
      }
      createOrderData = createAccidentAdapter(
        insurance,
        customerInfo,
        userInfo.mUid
      );
      const { data } = yield AppApiInstance.createAccidentInsurance(
        createOrderData
      );
      if (data) {
        responseCreateOrder = data.createPolicyByType;
      }
    } else if(type === TYPE_INSURANCE.MOTOBIKE) {
      // bảo hiểm xe máy
      if (insuranceDetail.productId) {
        // trường hợp từ trang chủ ( list bảo hiểm ) cần get detail bảo hiểm để post lên lại
        const motobikeInsuranceDetail = yield AppApiInstance.getMotobikeInsuranceDetail(
          { productId: insuranceDetail.productId, langId: "vi" }
        );

        insurance = {
          branch_id: motobikeInsuranceDetail.data.getMotobikeInsuranceDetail.branch_id,
          tenantId: motobikeInsuranceDetail.data.getMotobikeInsuranceDetail.tenantId,
          translation: motobikeInsuranceDetail.data.getMotobikeInsuranceDetail.translation,
          ...insuranceDetail,
          ...motobikeInsuranceDetail.data.getMotobikeInsuranceDetail
        }
      }
      createOrderData = createMotobikeAdapter(
        {...insurance, productId: insuranceDetail.productId},
        customerInfo,
        vehicleInfo,
        recipient,
        userInfo.mUid
      );
      responseCreateOrder = yield AppApiInstance.createMotobikeInsurance(
        createOrderData
      );
      if (responseCreateOrder.data) {
        responseCreateOrder = responseCreateOrder.data
          .createPolicy;
      }
    } else if(type === TYPE_INSURANCE.CAR){
      // bảo hiểm xe ô tô
      if (insuranceDetail.productId) {
        // trường hợp từ trang chủ ( list bảo hiểm )
        const carInsuranceDetail = yield AppApiInstance.getMotobikeInsuranceDetail(
          { productId: insuranceDetail.productId, langId: "vi" }
        );
        insurance = {
          branch_id: carInsuranceDetail.data.getMotobikeInsuranceDetail.branch_id,
          translation: carInsuranceDetail.data.getMotobikeInsuranceDetail.translation,
          ...insuranceDetail
        }
      }else {
        insurance = {
          ...insuranceDetail,
          benefits: insuranceDetail.dataFeeCar.products[0].benefits
        }
      }
      createOrderData = createCarAdapter(
        {...insurance, productId: insuranceDetail.productId},
        customerInfo,
        vehicleInfo,
        recipient,
        userInfo.mUid
      );

      responseCreateOrder = yield AppApiInstance.createCarInsurance(
        createOrderData
      );
      if (responseCreateOrder.data) {
        responseCreateOrder = responseCreateOrder.data
          .createPolicy;
      }
    }else if(type === TYPE_INSURANCE.HOME) {
      createOrderData = createHomeAdapter(
        insuranceDetail,
        customerInfo,
        homeInfo,
        recipient,
        userInfo.mUid
      );

      responseCreateOrder = yield AppApiInstance.createHomeInsurance(
        createOrderData
      );
      if (responseCreateOrder.data) {
        responseCreateOrder = responseCreateOrder.data
          .createPolicyHome;
      }
    }
    if (responseCreateOrder) {
      yield put({
        type: types.CREATE_ORDER_SUCCESS,
        responseCreateOrder,
        createOrderData,
        insurance,
      });
    } else {
      yield put({
        type: types.CREATE_ORDER_FAIL,
        errMsg: ERROR_MESSAGES.NORMAL,
      });
    }
  } catch (err) {
    const { vehicleInfo, type } = payload;
    const errMsg = (err.message.includes(vehicleInfo.licensePlates) && type === TYPE_INSURANCE.CAR) ? `Biển số xe ${vehicleInfo.licensePlates} đã được sử dụng` : ERROR_MESSAGES.NORMAL
    yield put({ type: types.CREATE_ORDER_FAIL, errMsg: errMsg });
  }
}

function* createOrderCallback({ payload }) {
  try {
    const {
      type,
      insuranceDetail,
      customerInfo,
      vehicleInfo,
      recipient,
      callback
    } = payload;
    let responseCreateOrder,
      insurance = insuranceDetail,
      createOrderData = null;
    const { userInfo } = store.getState().appParams;
    if (type === TYPE_INSURANCE.ACCIDENT) {
      if (insuranceDetail.planId) {
        const resInsuredMemberDetail = yield AppApiInstance.getPersonalAccidentInsuranceDetail(
          { planIds: insuranceDetail.planId, langId: "vi" }
        );
        insurance = resInsuredMemberDetail.data
          .getHealthInsuranceDetail[0];
      }
      createOrderData = createAccidentAdapter(
        insurance,
        customerInfo,
        userInfo.mUid
      );
      const { data } = yield AppApiInstance.createAccidentInsurance(
        createOrderData
      );
      if (data) {
        responseCreateOrder = data.createPolicyByType;
      }
    } else if(type === TYPE_INSURANCE.MOTOBIKE) {
      if (insuranceDetail.productId) {
        const motobikeInsuranceDetail = yield AppApiInstance.getMotobikeInsuranceDetail(
          { productId: insuranceDetail.productId, langId: "vi" }
        );
        insurance = {
          branch_id: motobikeInsuranceDetail.data.getMotobikeInsuranceDetail.branch_id,
          tenantId: motobikeInsuranceDetail.data.getMotobikeInsuranceDetail.tenantId,
          translation: motobikeInsuranceDetail.data.getMotobikeInsuranceDetail.translation,
          ...insuranceDetail,
          ...motobikeInsuranceDetail.data.getMotobikeInsuranceDetail
        }
      }
      createOrderData = createMotobikeAdapter(
        {...insurance, productId: insuranceDetail.productId},
        customerInfo,
        vehicleInfo,
        recipient,
        userInfo.mUid
      );
      responseCreateOrder = yield AppApiInstance.createMotobikeInsurance(
        createOrderData
      );
      if (responseCreateOrder.data) {
        responseCreateOrder = responseCreateOrder.data.createPolicy;
      }
    } else if(type === TYPE_INSURANCE.CAR){
      if (insuranceDetail.productId) {
        const carInsuranceDetail = yield AppApiInstance.getMotobikeInsuranceDetail(
          { productId: insuranceDetail.productId, langId: "vi" }
        );
        insurance = {
          branch_id: carInsuranceDetail.data.getMotobikeInsuranceDetail.branch_id,
          translation: carInsuranceDetail.data.getMotobikeInsuranceDetail.translation,
          ...insuranceDetail
        }
      }
      createOrderData = createCarAdapter(
        {...insurance, productId: insuranceDetail.productId},
        customerInfo,
        vehicleInfo,
        recipient,
        userInfo.mUid
      );
      responseCreateOrder = yield AppApiInstance.createCarInsurance(
        createOrderData
      );
      if (responseCreateOrder.data) {
        responseCreateOrder = responseCreateOrder.data.createPolicy;
      }
    }
    if (responseCreateOrder) {
      yield put({
        type: types.CREATE_ORDER_SUCCESS,
        responseCreateOrder,
        createOrderData,
        insurance,
      });
      yield callback(responseCreateOrder)
    } else {
      yield put({
        type: types.CREATE_ORDER_FAIL,
        errMsg: ERROR_MESSAGES.NORMAL,
      });
    }
  } catch (err) {
    const { vehicleInfo, type } = payload;
    const errMsg = (err.message.includes(vehicleInfo.licensePlates) && type === TYPE_INSURANCE.CAR) ? `Biển số xe ${vehicleInfo.licensePlates} đã được sử dụng` : ERROR_MESSAGES.NORMAL
    yield put({ type: types.CREATE_ORDER_FAIL, errMsg: errMsg });
  }
}

const caculatorSumInsured = (benefit, numberOfYearsUsed, marketPrice) => {
  let sumInsured = 0;
  if(benefit.benefitCode === "Home-House") {
    sumInsured = 0;
  }else {
    const valueMarketPrice = +marketPrice
    const caculator = benefit.rate.find(f => numberOfYearsUsed >= f.premiumRule.age_min && numberOfYearsUsed <= f.premiumRule.age_max);
    sumInsured = sumInsured + valueMarketPrice * +caculator.premiumRule.premium_formula.split("*")[0] + (valueMarketPrice * +caculator.premiumRule.premium_formula.split("*")[0] * 0.1);
  }
  return sumInsured;
}

const setAppID = (productType) => {
  let appID = APPID;
  if([PRODUCT_TYPE.motorbike_liability, PRODUCT_TYPE.personal_accident].includes(productType)) {
    appID = APPID;
  }else if(productType === PRODUCT_TYPE.car) {
    appID = APPID_CAR;
  }else {
    appID = APPID_HOME;
  }
  return appID;
}

function* createPayOrder({ payload }) {
  try {
    const { contractId, productType } = payload;
    const appID = setAppID(productType);
    const dataParse = yield AppApiInstance.payOrder(contractId);
    if (dataParse && dataParse.data) {
      yield put({
        type: types.CREATE_PAY_ORDER_SUCCESS,
        paymentInfo: {
          order_url: dataParse.data.createOrder.orderUrl,
          zptranstoken: dataParse.data.createOrder.zpTransToken,
          message: dataParse.data.createOrder.message,
          appid: appID,
        },
      });
    } else {
      yield put({
        type: types.CREATE_PAY_ORDER_FAIL,
        errMsg: ERROR_MESSAGES.NORMAL,
      });
    }
  } catch (err) {
    yield put({
      type: types.CREATE_PAY_ORDER_FAIL,
      errMsg: ERROR_MESSAGES.NORMAL,
    });
  }
}

function* createPolicyDraft({ payload }) {
  const {
    type,
    insuranceDetail,
    customerInfo,
    vehicleInfo,
  } = payload;

  let createOrderData = null;
  createOrderData = createPolicyDraftAdapter(insuranceDetail, customerInfo, vehicleInfo);
  if(type === TYPE_INSURANCE.CAR || type === TYPE_INSURANCE.MOTOBIKE) {
    yield AppApiInstance.createPolicyDraft(createOrderData);
  }
}

export function* paymentWatcher() {
  yield takeLatest(types.CREATING_ORDER, createOrder);
  yield takeLatest(types.CREATING_ORDER_CALLBACK, createOrderCallback);
  yield takeLatest(types.CREATING_PAY_ORDER, createPayOrder);
  yield takeLatest(types.CREATING_POLICY_DRAFT, createPolicyDraft);
}
